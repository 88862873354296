import $ from 'jquery';
import Component from '../models/Component';

export default class PlayVideo extends Component {
  constructor(element, context) {
    super(element, context);

        const $playButton = this.element.find('#play-button');
        const $video = this.element.find('#video');
    
        // Quando l'utente clicca sul pulsante Play
        $playButton.on('click', function () {
            $video[0].play(); // Avvia la riproduzione
            $playButton.hide(); // Nascondi il pulsante Play
        });
    
        // Mostra nuovamente il pulsante Play alla fine del video
        $video.on('ended', function () {
            $playButton.show(); // Mostra il pulsante Play
        });
    

    $(() => {
        this.init();
    });
  }
}